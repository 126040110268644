<template>
  <Dialog v-if="visible" v-model="visible" :options="dialogOptions.dialog">
    <v-form ref="form">
      <v-row style="position: relative">
        <v-col
          :cols="12"
          style="display: flex; justify-content: center; align-items: center"
        >
          <img
            :src="dialogOptions.imgBase64"
            width="600"
            height="376"
            style="object-fit: contain"
            alt="imgBase64"
          />
        </v-col>
        <v-col :cols="12">
          <v-text-field
            v-model="description"
            :rules="[
              () => !!description || '请输入违规描述！',
              (v) => (v && v.length <= 200) || '最大输入200字符！',
            ]"
            placeholder="请输入违规描述"
            solo
            counter="200"
          >
            <template #prepend
              ><div class="prepend-lable">违规描述：</div></template
            >
          </v-text-field>
        </v-col>
        <v-col
          :cols="12"
          style="display: flex; justify-content: center; align-items: center"
        >
          <img
            src="@/assets/screen/serviceSupervision/cancel.png"
            alt="取消"
            style="cursor: pointer; margin-right: 6px"
            @click="visible = false"
          />
          <img
            src="@/assets/screen/serviceSupervision/save.png"
            alt="保存"
            style="cursor: pointer"
            @click="onSubmit('report')"
          />
        </v-col>
        <v-overlay absolute :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
          <div>数据提交中...</div>
        </v-overlay>
      </v-row>
    </v-form>
  </Dialog>
</template>

<script>
import { assiginDeep } from '@/views/screen/config';
import exception from '@/utils/exception';
import Dialog from '@/views/screen/components/Dialog.vue';
export default {
  //组件注册
  components: {
    Dialog,
  },
  //组件传值
  props: {},
  data() {
    return {
      overlay: false,
      description: null,
      visible: false,
      dialogOptions: {
        dialog: {
          width: 791,
          title: '违规截图上传',
        },
      },
    };
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {
    visible() {
      if (!this.visible) {
        this.description = null;
        this.overlay = false;
      }
    },
  },
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    openDialog(options = {}) {
      this.dialogOptions = assiginDeep(this.dialogOptions, { ...options });
      this.visible = true;
    },
    async onSubmit(type, remoteInfo = {}) {
      try {
        if (this.$refs.form && !this.$refs.form.validate()) return;
        this.overlay = true;
        const data = await this.$http.post(
          `/${this.$businessISG}/monitor/serve/remote-${type}`,
          {
            ...remoteInfo,
            ...this.dialogOptions.remoteInfo,
            imgBase64: this.dialogOptions.imgBase64,
            description: this.description,
          },
          {
            'Content-Type': 'application/json;charset=UTF-8',
          }
        );
        if (data.code != 200) throw data.message;
        this.overlay = false;
        exception.toastInfo(data.message);
        this.visible = false;
        this.$emit('success', type);
      } catch (error) {
        exception.toastError(error + '');
        this.overlay = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-input {
  margin: 0 20px;
  .v-input__prepend-outer {
    margin-top: 16px;
  }
  .v-input__control {
    .v-input__slot {
      background: #092e4e;
      border: 1px solid #278ddb;
      input {
        color: rgb(238, 249, 255);
        font-size: 14px;
        &::-webkit-input-placeholder {
          font-size: 14px;
          color: #699bc6;
        }
      }
    }
    .v-messages__message {
      font-size: 14px;
    }
  }
}
.prepend-lable {
  position: relative;
  color: #d2e2e7;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  &::before {
    content: '*';
    position: absolute;
    left: -12px;
    color: red;
  }
}
</style>
